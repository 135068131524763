export const DSystemDetail = [
  {
    created_date: new Date(),
    title: '1.Schedule Class Manager',
    detail: 'The Schedule Class Manager can use the system to create classes and prepare schedules and they can sort all the classes to students depending on their majors. Doing this can help student pick class base on their major and academic year they are in. The Schedule Class Manager can also enroll students, add classes for students and drop classes for students.'
  },
  {
    created_date: new Date(),
    title: '2.Test Center Manager',
    detail: `This system will benefit both students and staffs in the testing center. For student, they can register for testing such as tests to enter academic program and language program. Furthermore, they can make payment directly from their mobile app. After the payment successfully gone through, students will receive a serial number and they can use that number to show testing center staff and can proceed to testing. After completing the test, students can check their result online from their app. For staff, they can manage the all the students registered and they can make the examination process easier. Student will give the registered number and they can verify the number with the system if the registration is valid, then students can proceed to take their tests. This will make the process of the testing go smoothly and provide a pleasant experience for both students and staffs. With this system, we can move the testing process from paper based to adaptive placement test (MCQ and writing) on digital platforms such as computers or tablets. Last but not least, for certain types of test, students will be able to get their result immediately and process to enroll for their assigned course.
    `
  },
  {
    created_date: new Date(),
    title: '3.Cashier & Billing System',
    detail: `Billing System is the all-in-one payment management system. Staffs can check student payment and each transaction status. They can also send alerts to students to remind them if they have any outstanding invoice. This system is intelligently linked with scholarship system so when students make payment for their tuition, the system will update discount according to their scholarship status. This also applies to special scholarships like for discount for monks. When students are behind their payments, the system can also apply penalty fee to their invoices, and it will also send an alert to the student portal app. Because all transactions are automatically recorded, management can always track and view any report at any given time. While this is already powerful, we took our system even further. With hardware integrations, management can also track all types of activities such as which cashier open their cash drawer.`
  },
  {
    created_date: new Date(),
    title: '4.Scholarship System',
    detail: `Scholarships are important for many students, but it can be difficult to manage and track. The process takes a lot of time and requires approval from many levels. After that, there has to be a way to communicate between each department so that they perform their tasks accordingly. This problem is most difficult when it comes to the cashier office. Miscommunication about scholarship with the cashier office could result in financial and accounting errors. With our scholarship systems, everything is tracked and updated to all related departments. An approval process will ensure that only the director can approve a scholarship and when it happens cashier office won’t need to do anything because a discount will appear automatically on the students’ receipts. Scholarships can be costly, so management always want to be fully aware of how much scholarship the school is giving out. Our systems work together to generate financial reports so that the directors can make informed decisions.`
  },
  {
    created_date: new Date(),
    title: '5.Enrollment System Manager',
    detail: `Every new term, enrollment office needs to work as fast as they can to facilitate students with their class schedules. Our system is built with performance and ease of use to make sure the process is quick and easy With our systems, students can even make request to add or drop their classes from the student portal mobile app. The requests will then go to the dean of each departments for their approval.`
  },
  {
    created_date: new Date(),
    title: '6.Transcript System Manager',
    detail: `Transcript System is an important department that perform many functions for the students. Our systems allow the department to easily perform many tasks such as grading students, generate official documents such as transcripts and certificates. On top of this, the department is also informed if the fee has been paid before they process the work for students. The systems can also send alerts to students when request has been completed.`
  },
  {
    created_date: new Date(),
    title: '7.Faculty & Departments Manager',
    detail: `The dean of a faculty is frequently busy and spend a lot of time on the road traveling. This is why we design this system to work on all platforms from web to iOS and Android. We understand that there are many things that may require approval from the deans and our systems can allow deans to review and approve all requests from class schedule for the new terms and students’ requests to add or drop their classes. With our system, students do not need to make appointment with the deans for small matters anymore and their enrollment process can go much faster with pleasant experience for both staffs and students.`
  },
  {
    created_date: new Date(),
    title: '8.Administration',
    detail: `To ensure the quality of education, an institution wants to make sure that professors are always attending their lectures and be punctual. The admin systems can record attendances and any punctuality problem. It can also be extended for using to manage classrooms for professor when the new term starts and also for keeping track of classroom reservation.`
  },
  {
    created_date: new Date(),
    title: '9.Human Resource',
    detail: `One of the most important aspect in a business is the HR department. Therefore, this department requires a system that can help them keep track of employees. One of the main features of our HR system is the work time tracking that can be used to generate reports. The system also keeps track of employee’s time-off and unpaid leaves. This information is also deeply integrated with the Payroll system for the Accounting department as well.`
  },
]


export const ProductFeature = [
  {
    title: 'Administration & Teaching Attendance',
  },
  {
    title: 'Cashier & Billing System',
  },
  {
    title: 'Placement Test System',
  },
  {
    title: 'Test Center Manager',
  },
  {
    title: 'Bookstore System',
  },
  {
    title: 'Book Club: Reading & Exercise',
  },
  {
    title: 'Class Enrollment Manager',
  },
  {
    title: 'Classroom Online',
  },
  {
    title: 'Classroom Mobile App',
  },
  {
    title: 'Resource Planning Manager',
  },
  {
    title: 'Scholarship Manager',
  },
  {
    title: 'Schedule Class Manager',
  },
  {
    title: 'Registrar System Manager',
  },
  {
    title: 'Faculty Manager',
  },
  {
    title: 'Human Resources',
  },
  {
    title: 'Finance',
  },
  {
    title: 'Student Portal',
  },
  {
    title: 'Management Team Mobile App',
  },
  {
    title: 'Parents App',
  },
  {
    title: 'Mobile Payment',
  }, {
    title: 'Tuition Fee & Payment Tracking',
  }, {
    title: 'Support',
  },
]