import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DSystemDetail, ProductFeature } from 'src/app/dummy/systemdetail';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public Data = DSystemDetail
  public ProductFeature = ProductFeature
  form: FormGroup;
  pwdlenght:AbstractControl;
  pwdnumber:AbstractControl;
  characters = 'abcdefghijklmnopqrstuvwxyz1234567890!@#$%^&*()?_+<>'
  password: any;

  constructor(
    public route:ActivatedRoute,
    public router:Router,
    private fb: FormBuilder
  ) {
  }

  buildForm(): void {
    this.form = this.fb.group({
      pwdnumber: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
      pwdlenght: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
    });
    this.pwdnumber = this.form.controls["pwdnumber"];
    this.pwdlenght = this.form.controls["pwdlenght"];
  }

  generatePwd(){
    const PasswordNumner = Number(this.pwdnumber.value)
    const PasswordLenght = Number(this.pwdlenght.value)
    // const charactersLength = this.characters.length;
    const charactersLength =this.characters.length;
    let n = 0;
    while (n < PasswordNumner){
      let result='';
      for ( let i = 0; i < PasswordLenght; i++ ) {
        result += this.characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      console.log(result);
      n++;
      // return result;
    }
  }

  ngOnInit() {
    this.buildForm();
  }

}
