import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { NgsProfileComponent } from './component/ngs-profile/ngs-profile.component';
import { PrivacyEnComponent } from './pages/privacy-en/privacy-en.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';


const routes: Routes = [
  {
    path: '', component: AppLayoutComponent,
    children: [
      { path: '', component: HomeComponent }  ,
      { path: 'en', component: PrivacyEnComponent }  ,
      // { path: 'privacy', component: PrivacyComponent }  ,

    ]
  },

  {
    path: 'contact-us', component: AppLayoutComponent,
    children: [
      { path: '', component: ContactUsComponent }
    ]
  },

  {
    path: 'ngs-profile', component: AppLayoutComponent,
    children: [
      { path: ':type/:id', component: NgsProfileComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
