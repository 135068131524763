import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ngs-profile',
  templateUrl: './ngs-profile.component.html',
  styleUrls: ['./ngs-profile.component.scss']
})
export class NgsProfileComponent implements OnInit {
  data
  role:any;
  isMobile: boolean = false;

  constructor(
    private afs: AngularFirestore,
    private route:ActivatedRoute,
    public breakpointObserver: BreakpointObserver,

  ) { }

  ngOnInit() {
    this.breakpointObserver
      .observe(['(max-width: 812px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = state.matches;
        } else {
          this.isMobile = state.matches;
        }
      });
    this.route.params.subscribe(params=>{
      console.log(params)
      const {id, type} = params
      console.log('id', id)
      console.log('student', type)

      console.log('instructor', type)


      if(type==='student' && id){
        this.role='សិស្ស';
        this.afs.collection('students').doc(id).valueChanges().subscribe(data=>{
          this.data = data;
          console.log(data)
        })
      }
      if(type==="instructor"  && id){
        this.role='គ្រូ';
        this.afs.collection('employees').doc(id).valueChanges().subscribe(data=>{
          this.data = data;
          console.log(data)
        })
      }
    })
  }

}
